<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Expense Payment" slot="title" link="/helpContent/Expense Payment" />

    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-3">
          <inputText label="Name" v-model="form.name" rules="required" />
        </div>
        <div class="col-sm-3">
          <InputAmount
            label="Claim Amount"
            v-model="form.claimAmount"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputAmount
            label="Deduction"
            v-model="form.deduction"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <InputAmount
            label="Payable Amount"
            v-model="form.payableAmount"
            rules="required"
          />
        </div>
      </FormRow>
      <FormRow> </FormRow>
      <FormRow>
        <div class="col-sm-3">
          <inputText
            label="Narration "
            v-model="form.narration"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <FormCheckbox
            label="Claim Verified"
            v-model="form.claimVerified"
            rules="required"
            >Claim Verified</FormCheckbox
          >
        </div>
        <div class="col-sm-3">
          <label :class="$style.label">
            <RadioGroup
              v-model="form.mode"
              @on-change="onChange"
              style="margin-right: 10px;"
            >
              <Radio label="cash">
                Cash
              </Radio>
              <Radio label="cheque">
                Cheque
              </Radio>
            </RadioGroup>
            <span :class="$style.err">*</span>Mode
          </label>
          <!-- <span :class="$style.err" v-if="modeErr"> Required. </span> -->
          <!-- <FormYesNo label="Mode" v-model="form.mode" rules="required" ><span style="padding-left: 20px;">Mode</span></FormYesNo> -->
        </div>
      </FormRow>
      <FormRow v-if="chequeCon">
        <div class="col-sm-3">
          <FormSelect
            label="Account Number"
            :items="accountNumberList"
            item-name="NAME"
            item-value="id"
            v-model="form.accountNumberId"
            rules="required"
          />
        </div>
        <div class="col-sm-3">
          <inputText
            label="Cheque Number"
            v-model="form.chequeNumber"
            rules="required"
          />
        </div>
      </FormRow>
    </ValidationObserver>
    <br />
    <FormRow>
      <div class="col-sm-6">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData(1)">Approve</FormButton>
          <FormButton type="success" @click="sendData(0)">Reject</FormButton>
          <FormButton type="success" @click="detail()">Detail</FormButton>
        </ButtonGroup>
      </div>
    </FormRow>

    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <FormButton
          @click="
            () => {
              $router.go(-1);
            }
          "
          >Ok</FormButton
        >
      </div>
    </Popup>
  </FormWrapper>
</template>
<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import { expenseClaimNotPaid } from "../api";
import { RadioGroup, Radio } from "view-design";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "add",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    RadioGroup,
    Radio,
  },
  data() {
    return {
      showMessage: {
        isVisible: false,
      },
      form: {
        name: "",
        claimAmount: "",
        deduction: "",
        payableAmount: "",
        claimVerified: "",
        mode: "cash",
        narration: "",
        chequeNumber: "",
        accountNumberId: null,
        id: null,
      },
      accountNumberList: [],
      chequeCon: false,
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      // modeErr:false
    };
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  watch: {},

  created() {
 
    if (atob(this.$route.params.id)) {
      this.form.id=atob(this.$route.params.id);
      this.getData();
    }
  },
  methods: {
    detail() {
      this.$router.push({ path: `/expenseClaim/view/${btoa(this.form.id)}` });
    },
    getData() {
      const { key, order } = this.sorting;
      const { perPage, page } = this.pagination;
      const filters = {};
      filters.id = this.form.id;

      const data = {
        filterjson: {
          filter: [filters],
          sort: [{ key, order }],
          paging: [
            {
              startIndex: page,
              pagesize: perPage,
            },
          ],
        },
      };
      expenseClaimNotPaid(data).then((res) => {
        this.form = {
          name: res.data[0].Name,
          claimAmount: res.data[0].amount,
          deduction: res.data[0].Deduction == null ? 0 : res.data[0].Deduction,
          payableAmount: res.data[0].amount,
          claimVerified: res.data[0].claimVerified,
          mode: "cash",
          narration: res.data[0].narration,
          chequeNumber: res.data[0].chequeNumber,
          accountNumberId: res.data[0].accountNumberId,
          id: res.data[0].id,
        };
      });
    },
    sendData(via) {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          // this.modeErr=false
          const data = {
            name: this.form.name,
            claimAmount: this.form.claimAmount,
            deduction: this.form.deduction,
            payableAmount: this.form.claimAmount,
            claimVerified: this.form.claimVerified,
            mode: this.form.mode,
            narration: this.form.narration,
            chequeNumber: this.form.chequeNumber,
            accountNumberId: this.form.accountNumberId,
          };
        }
      });
    },
    onChange(val) {
      if (val === "cheque") {
        this.chequeCon = true;
      } else {
        this.chequeCon = false;
      }
    },
  },
};
</script>

<style lang="scss" module>
.label {
  display: flex;
}
.err {
  font-size: 11px;
  line-height: 16px;
  bottom: -18px;
  font-weight: 400;
  color: #ff3547;
}
</style>
